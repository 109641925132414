<template>
  <div class="relative overflow-x-auto rounded-xl bg-white">
    <div class="flex flex-row items-center py-1 lg:px-0 sm:px-6 px-6">
      <div class="justify-items-start p-4 text-lg w-full">
        <p v-if="detailPage">{{ detailPageIndicator }}</p>
        <p v-else class="pl-2">Indicators</p>
      </div>
      <div class="flex space-x-4 justify-end py-4 px-10 w-1/2 z-10">
        <v-select
          v-if="!indicatorDetailsPage && showPracticeFilter === true"
          class="sm:w-64 lg:w-36 xl:w-72"
          :options="practices"
          label="practice_name"
          @update:modelValue="setPracticeForFilter"
          buttonWidth="sm:w-52 lg:w-36 xl:w-64"
          placeholder="Practice"
        />
        <v-select
          v-model="sortOrder"
          class="sm:w-64 lg:w-36 xl:w-72"
          label="label"
          placeholder="Sort By"
          :options="sortingList"
          @update:modelValue="orderByFunc"
        >
        </v-select>
      </div>
    </div>
    <SearchField
      @input="searchIndicators"
      :placeholder="searchPlaceholder"
      class="border-t border-gray-100"
    />
    <table class="w-full text-sm text-left">
      <thead class="text-sm text-white bg-teal border-b">
        <tr>
          <th
            scope="col"
            class="ml-12 py-4 tracking-wider"
            :class="
              role === 'manager'
                ? ' grid grid-cols-6 gap-x-20'
                : 'gap-6 grid grid-cols-7'
            "
          >
            <span
              v-for="header in props.tableHeadings"
              :key="header"
              :class="{
                'col-span-4': header === 'Name',
                'col-span-5': header === 'Practice',
              }"
              >{{ header }}</span
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in tableData" :key="data?.id">
          <td
            class="py-4 font-medium text-gray-900 overflow-hidden items-center"
            :class="
              role === 'manager'
                ? ' grid grid-cols-6 gap-x-20'
                : 'gap-4 grid grid-cols-7'
            "
          >
            <div class="col-span-4" :class="{ 'col-span-5': detailPage }">
              <div class="flex items-center">
                <button @click="toggleSubIndicators(data.id)" class="px-6">
                  <span class="material-icons">{{
                    data?.showSubIndicators
                      ? "keyboard_arrow_down"
                      : "keyboard_arrow_up"
                  }}</span>
                </button>
                <div class="flex flex-col w-full">
                  <span class="py-2">{{
                    detailPage ? data?.practice_name : data?.name
                  }}</span>
                  <div class="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                    <div
                      class="h-2.5 rounded-full"
                      :style="progressBarStyle(data?.achieved_percentage)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-6 py-4">{{ data?.achieved_percentage }}%</div>
            <div class="px-1 py-4">
              {{ data?.achieved_points }} / {{ data?.total_points }}
            </div>
            <div v-if="!detailPage">
              <div
                v-if="!props?.detailPage && role !== 'manager'"
                @click="openIndicatorsDetail(data?.id, data?.name)"
                class="flex items-center px-6 text-teal font-semibold opacity-70 cursor-pointer"
              >
                Open<i class="material-icons text-lg ml-1">launch</i>
              </div>
              <div v-else-if="role === 'hq'">
                <button
                  class="py-2 px-5 border hover:text-white hover:border-transparent rounded"
                  :style="actionButtonStyle(data?.percentage)"
                  :class="
                    data?.percentage >= 80
                      ? 'hover:bg-teal border-teal text-teal'
                      : data?.percentage >= 50
                      ? 'hover:bg-orange border-orange text-orange'
                      : 'hover:bg-red-500 border-red-500 text-red-500'
                  "
                  @click="routeToActionPlan(data?.id)"
                >
                  Take Action
                </button>
              </div>
            </div>
          </td>
          <div
            v-if="data?.showSubIndicators"
            class="border-t border-b border-teal bg-silver"
          >
            <SubIndicatorsTable
              :detailPage="false"
              :data="detailPage ? subIndicatorStats : data?.sub_indicators"
              :showAction="true"
              :actionPlanPageRoute="actionPlanPageRoute"
              :indicatorId="data?.id"
              :indicatorName="data?.name"
            />
          </div>
        </tr>
      </tbody>
    </table>
    <Pagination
      :currentPage="props.indicatorStats?.current_page"
      :totalPages="props.indicatorStats?.last_page"
      @page-changed="fetchPage"
    />
  </div>
</template>

<script setup>
import {
  defineProps,
  toRefs,
  ref,
  defineEmits,
  computed,
  onMounted,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Pagination from "@/components/newUi/atoms/Pagination.vue";

import SubIndicatorsTable from "@/components/newUi/tables/SubIndicatorsTable.vue";
import SearchField from "@/components/newUi/baseComponents/SearchField.vue";

const router = useRouter();
const route = useRoute();
const store = useStore();

const sortingList = ref([
  { label: "Ascending", value: "ASC" },
  { label: "Descending", value: "DESC" },
]);

const indicatorDetailsPage = computed(() =>
  route.path.includes("hq-indicator-details")
);
const sortOrder = ref(null);
const subIndicatorStats = ref([]);
const orderByValue = ref(null);
const searchTextValue = ref(null);
const currentPage = ref(props.indicatorStats?.current_page);
const practiceForFilter = ref(null);
const detailPageIndicator = localStorage.getItem("indicator");
const role = localStorage.getItem("role");
const actionPlanPageRoute =
  role === "hq" ? "hq-QOF-ActionPlan" : "ma-subIndicator-ActionPlan";
const practices = ref([]);

const emits = defineEmits([
  "orderBy",
  "searchIndicator",
  "practiceFilter",
  "pagination",
]);

const props = defineProps({
  indicatorStats: {
    type: Array,
    required: true,
  },
  tableData: {
    type: Array,
    required: true,
  },
  tableTitle: {
    type: String,
    required: true,
  },
  tableHeadings: {
    type: Array,
    required: true,
  },
  detailPage: {
    type: Boolean,
    default: false,
  },
  searchPlaceholder: {
    type: String,
    default: "Search Name",
  },
  showPracticeFilter: {
    type: Boolean,
    default: true,
  },
});

const { tableData } = toRefs(props);

onMounted(async () => {
  practices.value = await store.dispatch("patientSurveys/loadPractices");
});

const progressBarStyle = (value) => {
  return {
    width: `${value}%`,
    "background-color":
      value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
  };
};

const toggleSubIndicators = async (id) => {
  const rowData = tableData.value.find((data) => data.id === id);
  if (rowData) {
    rowData.showSubIndicators = !rowData.showSubIndicators;
    if (props.detailPage && rowData.showSubIndicators) {
      if (role === "hq") {
        subIndicatorStats.value = await store.dispatch(
          "hqQof/fetchSubIndicatorWithPractices",
          { practiceId: id, indicatorId: route.params.id }
        );
      } else if (role === "manager") {
        await store.dispatch("maQof/fetchSubIndicatorStats", {
          practiceId: localStorage.getItem("practice"),
          indicatorId: route.params.id,
        });
        const subIndicators = computed(() => {
          return store.getters["maQof/getSubIndicatorStats"];
        });
        subIndicatorStats.value = subIndicators.value;
      }
    }
  }
};

const actionButtonStyle = (value) => {
  return {
    "border-color":
      value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
    color: value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
    "background-color-color":
      value >= 80 ? "#00A499" : value >= 50 ? "#EEA23E" : "#E2341D",
  };
};

const openIndicatorsDetail = (id, name) => {
  localStorage.setItem("indicator", name);
  if (role === "hq") {
    router.push({ name: "hq-indicator-details", params: { id: id } });
  } else if (role === "manager") {
    router.push({ name: "ma-indicator-details", params: { id: id } });
  }
};

const orderByFunc = (order) => {
  orderByValue.value = order ? order.value : order;
  emits("orderBy", {
    name: searchTextValue.value,
    order_by: orderByValue.value,
    practice: practiceForFilter.value,
    page: 1,
  });
};

const searchIndicators = (searchText) => {
  searchTextValue.value = searchText;
  emits("searchIndicator", {
    name: searchTextValue.value,
    order_by: orderByValue.value,
    practice: practiceForFilter.value,
    page: 1,
  });
};
const setPracticeForFilter = (practice) => {
    practiceForFilter.value = practice ? practice?.id : null;
    emits("practiceFilter", {
      name: searchTextValue.value,
      order_by: orderByValue.value,
      practice: practiceForFilter.value,
      page: currentPage.value,
    });
};
const fetchPage = (page) => {
  currentPage.value = page;
  emits("pagination", {
    name: searchTextValue.value,
    order_by: orderByValue.value,
    practice: practiceForFilter.value,
    page: currentPage.value,
  });
};
</script>
