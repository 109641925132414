<template>
  <div>
    <div class="flex h-12">
      <div
        class="flex sm:flex-col md:flex-row w-full border-2 border-none bg-white rounded-md justify-between px-4"
      >
        <div class="relative w-full">
          <input
            v-model="searchInput"
            type="text"
            class="px-4 py-3 w-full bg-transparent outline-none"
            :placeholder="placeHolder"
            @input="toggleIconVisibility($event)"
          />
        </div>
        <span
          class="material-icons p-3 pr-5 text-gray-400"
          :style="{ display: showIcon ? 'block' : 'none' }"
          >search</span
        >
        <!-- <button class="px-4 text-white bg-teal border-l rounded-3xl">
          Search
        </button> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["input"],
  props: {
    placeholder: String,
  },
  data() {
    return {
      searchInput: "",
      showIcon: true,
      debounceTimer: null,
      placeHolder: this.placeholder,
    };
  },
  methods: {
    toggleIconVisibility() {
      this.showIcon = !this.searchInput;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.$emit("input", this.searchInput);
      }, 1000);
    },
  },
};
</script>
