<template>
  <div class="flex flex-col gap-8">
    <div class="border-2 border-grey-700 rounded-md">
      <IndicatorsTable
        :key="indicators"
        :indicatorStats="indicatorStats"
        :tableData="indicatorStats.data"
        :tableHeadings="surveyTableHeadings"
        :detailPage="true"
        :searchPlaceholder="'Search Practice'"
        @orderBy="indicatorsFilters"
        @searchIndicator="indicatorsFilters"
        @pagination="indicatorsFilters"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import IndicatorsTable from "@/components/newUi/tables/IndicatorsTable.vue";

const route = useRoute();
const store = useStore();
const indicatorStats = ref([]);

const indicatorId = route.params?.id;

onMounted(async () => {
  const payload = {
    indicatorId: indicatorId,
    page: 1,
  };
  indicatorStats.value = await store.dispatch(
    "hqQof/fetchIndicatorWithPractices",
    payload
  );
});

const indicatorsFilters = async (payload) => {
  const localPayload = payload;
  localPayload.indicatorId = indicatorId;
  indicatorStats.value = await store.dispatch(
    "hqQof/fetchIndicatorWithPractices",
    localPayload
  );
};

const surveyTableHeadings = ref(["Practice", "Percentage", "Points"]);
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
</style>
